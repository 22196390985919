import * as React from 'react';

import { Route, Routes } from 'react-router-dom';

import { useUserLocale } from '../state/locale/hooks/useUserLocale';
import Error404 from '../ui/_screens/404';
import About from '../ui/_screens/About';
import Articles from '../ui/_screens/Articles';
import Contact from '../ui/_screens/Contact';
import ContactSuccess from '../ui/_screens/ContactSuccess';
import Department from '../ui/_screens/Department';
import Estate from '../ui/_screens/Estate';
import Fund from '../ui/_screens/Fund';
import { ImpressumScreen } from '../ui/_screens/Impressum';
import Investment from '../ui/_screens/Investment';
import Investments from '../ui/_screens/Investments';
import Jobs from '../ui/_screens/Jobs';
import { PolicyScreen } from '../ui/_screens/Policy';
import Press from '../ui/_screens/Press';
import Purchase from '../ui/_screens/Purchase';
import Rent from '../ui/_screens/Rent';
import Sale from '../ui/_screens/Sale';
import SinglePage from '../ui/_screens/SinglePage';
import Sponsorship from '../ui/_screens/Sponsorship';
import Tenant from '../ui/_screens/Tenant';
import { Main } from '../ui/templates/Main';
import routes from './routes';

export const Router = () => {
  useUserLocale();

  return (
    <Routes>
      <Route path={routes.about} element={<About />} />
      <Route path={routes.contactSuccess} element={<ContactSuccess />} />
      <Route path={routes.contact} element={<Contact />} />
      <Route path={routes.estate} element={<Estate />} />
      <Route path={routes.fund} element={<Fund />} />
      <Route path={routes.impressum} element={<ImpressumScreen />} />
      <Route path={routes.indexDE} element={<Main />} />
      <Route path={routes.indexEN} element={<Main />} />
      <Route path={routes.indexPL} element={<Main />} />
      <Route path={routes.index} element={<Main />} />
      <Route path={routes.investments} element={<Investments />} />
      <Route path={routes.investment} element={<Investment />} />
      <Route path={routes.jobs} element={<Jobs />} />
      <Route path={routes.policy} element={<PolicyScreen />} />
      <Route path={routes.pressArticles} element={<Articles />} />
      <Route path={routes.press} element={<Press />} />
      <Route path={routes.purchase} element={<Purchase />} />
      <Route path={routes.rent} element={<Rent />} />
      <Route path={routes.sale} element={<Sale />} />
      <Route path={routes.singlePage} element={<SinglePage />} />
      <Route path={routes.sponsorshipDepartment} element={<Department />} />
      <Route path={routes.sponsorship} element={<Sponsorship />} />
      <Route path={routes.tenant} element={<Tenant />} />
      <Route path={'*'} element={<Error404 />} />
    </Routes>
  );
};
