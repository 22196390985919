import * as React from 'react';
import { FC, useRef } from 'react';

import useStickyNavbar from '../../../misc/hooks/useStickyNavbar';
import { Footer } from '../Footer';
import Navbar from '../Navbar';
import styles from './Grid.module.scss';

export const Grid: FC = ({ children }) => {
  const mainRef = useRef(document.createElement('main'));
  const { isSticky, showSticky, stickyAnimation } = useStickyNavbar(
    mainRef,
    true
  );

  return (
    <div className={styles.grid}>
      <Navbar
        isSticky={isSticky}
        showSticky={showSticky}
        stickyAnimation={stickyAnimation}
      />
      <main className={styles.content} ref={mainRef}>
        {children}
      </main>
      <Footer />
    </div>
  );
};
