import * as React from 'react';
import { createContext, FC, useMemo } from 'react';

import cn from 'classnames';

import { getBreakpoints } from '../../../misc/helpers/getBreakpoints';
import { useResizer } from '../../../misc/hooks/useResizer';

const initialState = {
  breakpoints: {
    isDesktop: false,
    isMobile: false,
    isTablet: false,
    noDesktop: false,
    noMobile: false,
    noTablet: false,
  },
  dimensions: {
    height: 0,
    width: 0,
  },
};

export const AppContext = createContext(initialState);

export const AppContextProvider: FC = ({ children }) => {
  const { height, width } = useResizer();
  const breakpoints = useMemo(() => getBreakpoints(width), [width]);

  return (
    <div
      className={cn({
        desktop: breakpoints.isDesktop,
        mobile: breakpoints.isMobile,
        tablet: breakpoints.isTablet,
      })}
    >
      <AppContext.Provider
        value={{
          breakpoints,
          dimensions: { height, width },
        }}
        children={children}
      />
    </div>
  );
};
