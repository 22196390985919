import { Languages } from '../../constants/Languages';

class GetDataService {
  public static getDataUri(path: string, lang?: Languages) {
    const base = process.env.REACT_APP_API_URL + path;

    return lang ? base + `?_locale=${lang}` : base;
  }
}

export default GetDataService;
