import * as React from 'react';
import { useEffect, useState } from 'react';

import { useLocation } from 'react-router-dom';
import { useRecoilValue } from 'recoil';

import ArticleApi from '../../../services/$articles-api';
import { IArticleResponse } from '../../../services/$articles-api/types';
import { useCurrentBreadcrumb } from '../../../state/breadcrumbs/hooks/useCurrentBreadrumb';
import { locale } from '../../../state/locale/atoms/locale';
import { HorizontalCard } from '../../reusable/HorizontalCard';
import { Loading } from '../../reusable/Loading';
import styles from './Articles.module.scss';

export const Articles = () => {
  const lang = useRecoilValue(locale);
  const slug = useLocation().pathname.split('/', 3)[2];
  const [page, setPage] = useState<IArticleResponse | null>(null);
  useCurrentBreadcrumb(page?.title);

  useEffect(() => {
    ArticleApi.getArticles(lang, slug).then((response) => setPage(response));
  }, [lang, slug]);

  const articleList = page?.articles
    .sort((a, b) => (a.date > b.date ? -1 : 1))
    .map((article) => (
      <HorizontalCard
        date={article.date}
        description={article.description}
        link={article.externalLink}
        linkBlank={true}
        image={process.env.REACT_APP_IMAGE_PREFIX + article.image.url}
        publisher={article.publisher}
        title={article.title}
        key={article.title}
      />
    ));

  return page ? (
    <div className={styles.articleListWrapper}>{articleList}</div>
  ) : (
    <Loading />
  );
};
