import * as React from 'react';
import { useEffect, useState } from 'react';

import { Carousel } from 'react-responsive-carousel';
import { useLocation } from 'react-router-dom';
import { useRecoilValue } from 'recoil';

import SponsorshipApi from '../../../services/$sponsorship-api';
import { ISponsorshipDepartment } from '../../../services/$sponsorship-api/types';
import { useCurrentBreadcrumb } from '../../../state/breadcrumbs/hooks/useCurrentBreadrumb';
import { locale } from '../../../state/locale/atoms/locale';
import { translate } from '../../../state/locale/selectors/translate';
import { Link } from '../../reusable/Link';
import { Loading } from '../../reusable/Loading';
import styles from './Department.module.scss';

export const Department = () => {
  const lang = useRecoilValue(locale);
  const i18n = useRecoilValue(translate).department;
  const slug = useLocation().pathname.split('/', 3)[2];
  const [department, setDepartment] = useState<ISponsorshipDepartment | null>(
    null
  );
  useCurrentBreadcrumb(department?.title);

  useEffect(() => {
    SponsorshipApi.getSponsorship(lang, slug).then((response) =>
      setDepartment(response[0])
    );
  }, [lang, slug]);

  const sections = department?.elements.map((section) => (
    <div className={styles.sectionWrapper} key={section.id}>
      {section.images.length > 1 ? (
        <Carousel
          autoPlay={true}
          dynamicHeight={true}
          emulateTouch={true}
          infiniteLoop={true}
          interval={5000}
          showArrows={true}
          showIndicators={true}
          showStatus={false}
          showThumbs={false}
          swipeable={true}
          transitionTime={500}
          useKeyboardArrows={false}
          className={styles.carousel}
        >
          {section.images.map((image) => (
            <div className={styles.imageContainer} key={image.id}>
              <img
                className={styles.image}
                src={process.env.REACT_APP_IMAGE_PREFIX + image.url}
                alt={section.title + image.id}
              />
            </div>
          ))}
        </Carousel>
      ) : (
        <div className={styles.imageContainer} key={section.title}>
          <img
            className={styles.image}
            src={process.env.REACT_APP_IMAGE_PREFIX + section.images[0].url}
            alt={section.title}
          />
        </div>
      )}
      <h2 className={styles.sectionTitle}>{section.title}</h2>
      <p className={styles.paragraph}>{section.description}</p>
      {section.link && (
        <Link className={styles.buttonLink} to={section.link} blank={true}>
          {i18n.button}
        </Link>
      )}
      <div className={styles.separator} />
    </div>
  ));

  return department ? (
    <div className={styles.departmentWrapper}>{sections}</div>
  ) : (
    <Loading />
  );
};
