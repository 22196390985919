import * as React from 'react';
import { FC } from 'react';

import { NavLink, Link as RouterLink } from 'react-router-dom';

import { regExp } from '../../../constants/RegExp';
import { ILinkProps } from './Link.types';

export const Link: FC<ILinkProps> = ({
  blank,
  children,
  className,
  navLink,
  onClick,
  to,
}) => {
  const isExternal = regExp.link.test(to);

  if (isExternal) {
    return (
      <a
        href={to}
        className={className}
        onClick={onClick}
        rel={'noopener noreferrer nofollow'}
        target={blank ? '_blank' : '_self'}
      >
        {children}
      </a>
    );
  }

  const isMail = regExp.email.test(to);

  if (isMail) {
    return (
      <a href={`mailto:${to}`} className={className} onClick={onClick}>
        {children}
      </a>
    );
  }

  if (navLink) {
    return (
      <NavLink to={to} className={className} onClick={onClick}>
        {children}
      </NavLink>
    );
  }

  return (
    <RouterLink to={to} className={className} onClick={onClick}>
      {children}
    </RouterLink>
  );
};
