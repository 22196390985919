import { useLayoutEffect } from 'react';

import { useSetRecoilState } from 'recoil';

import { currentBreadcrumb } from '../atoms/currentBreadcrumb';

/**
 * Hook sets current breadcrumb after the page is rendered
 *
 * title {string} - title of the breadcrumb to set
 */
export const useCurrentBreadcrumb = (title?: string | null) => {
  const setBreadcrumbs = useSetRecoilState(currentBreadcrumb);

  useLayoutEffect(() => {
    if (title) {
      setBreadcrumbs(title);
    }
  }, [title]); // eslint-disable-line react-hooks/exhaustive-deps
};
