/**
 * function that splits continuous text in string on enters
 *
 * @param {string} text
 *
 * @return {string[]} array of text string blocks
 */

const getDescriptionTextBlocks = (text: string): string[] => text.split('\n\n');

export default getDescriptionTextBlocks;
