import {
  CSSProperties,
  MutableRefObject,
  useLayoutEffect,
  useRef,
  useState,
} from 'react';

import throttle from 'lodash.throttle';
import { useSpring } from 'react-spring';

import getScrollPosition from './../helpers/getScrollPosition';

const config = {
  navHeight: 200,
  throttleTime: 20,
  topDistanceDesktop: 780,
  topDistanceMobile: 100,
};

function useStickyNavbar(
  element?: MutableRefObject<HTMLElement>,
  useWindow?: boolean
) {
  const position = useRef(getScrollPosition({ useWindow }));
  const [isSticky, toggleSticky] = useState(false);
  const [showSticky, toggleVisibility] = useState(false);

  const stickyAnimation: CSSProperties = useSpring({
    transform: showSticky ? 'translate3d(0, 0, 0)' : 'translate3d(0, -100%, 0)',
  });

  const handleSticky = () => {
    const prevPos = position.current;
    const currPos = getScrollPosition({ element, useWindow });

    const backward = currPos.y < prevPos.y;
    const forward = currPos.y > prevPos.y;

    if (currPos.y > config.navHeight && !isSticky) {
      toggleSticky(true);
    }

    if (currPos.y < config.navHeight && isSticky) {
      toggleSticky(false);
    }

    if (!showSticky && forward && currPos.y > config.topDistanceDesktop) {
      toggleVisibility(true);
    }

    if (showSticky && backward) {
      toggleVisibility(false);
    }

    position.current = currPos;
  };

  useLayoutEffect(() => {
    window.addEventListener(
      'scroll',
      throttle(handleSticky, config.throttleTime)
    );

    return () =>
      window.removeEventListener(
        'scroll',
        throttle(handleSticky, config.throttleTime)
      );
    // eslint-disable-next-line
  }, [isSticky, showSticky]);

  return {
    isSticky,
    showSticky,
    stickyAnimation,
  };
}

export default useStickyNavbar;
