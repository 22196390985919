import * as React from 'react';

import crest from '../../../../../misc/images/logos/westminster_crest.svg';
import styles from './Marker.module.scss';
import { IMarkerProps } from './Marker.types';

const Marker = ({ text }: IMarkerProps) => (
  <div className={styles.marker}>
    <div className={styles.popup}>
      <img className={styles.crest} src={crest} alt={text} />
      <p>{text}</p>
    </div>
  </div>
);

export default Marker;
