import * as React from 'react';
import { useEffect, useState } from 'react';

import cn from 'classnames';
import { useLocation } from 'react-router-dom';
import { useRecoilValue } from 'recoil';

import insertLineBreakMarkups from '../../../misc/helpers/insertLineBreakMarkups';
import scrollToHash from '../../../misc/helpers/scrollToHash';
import getDescriptionTextBlocks from '../../../misc/helpers/splitStringTextBlocks';
import EstateApi from '../../../services/$estate-api';
import { IEstateResponse } from '../../../services/$estate-api/types';
import { locale } from '../../../state/locale/atoms/locale';
import { translate } from '../../../state/locale/selectors/translate';
import { GallerySlider } from '../../reusable/GallerySlider';
import { GoogleMaps } from '../../reusable/GoogleMaps';
import { Link } from '../../reusable/Link';
import { Loading } from '../../reusable/Loading';
import styles from './Estate.module.scss';

export const Estate = () => {
  const lang = useRecoilValue(locale);
  const i18n = useRecoilValue(translate).estate;
  const location = useLocation();
  const [page, setPage] = useState<IEstateResponse | null>(null);

  useEffect(() => {
    EstateApi.getEstatePage(lang).then((response) => setPage(response));
  }, [lang]);

  useEffect(() => {
    scrollToHash(!!page, location.hash, 500);
  }, [lang, page, location.hash]);

  const criteriaList = page?.profileCriteria.map((criteria) => (
    <li className={styles.criteria} key={criteria.id}>
      <h3 className={styles.criteriaTitle}>{criteria.title}</h3>
      <ul className={styles.criteriaList}>
        {criteria.criteria.map((item) => (
          <li className={styles.criteriaItem} key={item.id}>
            <p className={styles.itemText}>{item.content}</p>
          </li>
        ))}
      </ul>
    </li>
  ));

  return page ? (
    <div className={styles.estateWrapper}>
      <h2 className={styles.pageTitle} id={'profile'}>
        {page.profileTitle}
      </h2>
      {getDescriptionTextBlocks(page.profileDescription).map(
        (textBlock, index) => (
          <p className={styles.paragraph} key={index.toString()}>
            {insertLineBreakMarkups(textBlock)}
          </p>
        )
      )}
      <ol className={styles.criteriaWrapper}>
        {criteriaList ? criteriaList : <Loading />}
      </ol>
      <Link className={styles.buttonLink} to={page.boxLink} blank={true}>
        {page.boxLinkTitle}
      </Link>
      <div className={styles.propositionContainer}>
        <p>{i18n.proposition}</p>
        <a href={'mailto:info@westminster.de'}>info@westminster.de</a>
      </div>
      <div className={styles.locationsWrapper}>
        <div className={styles.column}>
          <h2 className={styles.pageTitle} id={'locations'}>
            {page.locationsTitle}
          </h2>
          <p className={styles.paragraph}>{page.locationsDescription}</p>
        </div>
        <div className={cn(styles.column, styles.mapContainer)}>
          <GoogleMaps map={page.locationsMap} locale={lang} />
        </div>
      </div>
      <GallerySlider
        images={page.gallery.map(
          (image) => process.env.REACT_APP_IMAGE_PREFIX + image.url
        )}
      />
      <h2 className={cn(styles.paragraph, styles.linkDescription)}>
        {i18n.linkDescription}
      </h2>
      <Link className={styles.buttonLink} to={page.estateLink} blank={true}>
        {i18n.linkButton}
      </Link>
    </div>
  ) : (
    <Loading />
  );
};
