import * as React from 'react';

import { Carousel } from 'react-responsive-carousel';

import styles from './GallerySlider.module.scss';
import { IGallerySliderProps } from './GallerySlider.types';

const ALT_PREFIX = 'Westminster_';

export const GallerySlider = ({ images }: IGallerySliderProps) => {
  const imageList = images.map((image, index) => (
    <div className={styles.imageContainer} key={index.toString()}>
      <img className={styles.image} src={image} alt={ALT_PREFIX + index} />
    </div>
  ));

  return (
    <Carousel
      autoPlay={true}
      // centerSlidePercentage={50}
      // centerMode={true}
      dynamicHeight={true}
      emulateTouch={true}
      infiniteLoop={true}
      interval={5000}
      showArrows={true}
      showIndicators={imageList.length < 10}
      showStatus={false}
      showThumbs={true}
      swipeable={true}
      transitionTime={500}
      useKeyboardArrows={false}
      className={styles.sliderWrapper}
    >
      {imageList}
    </Carousel>
  );
};
