import * as React from 'react';

import { useRecoilValue } from 'recoil';
import useSWR from 'swr';

import { Ids } from '../../../constants/Ids';
import { IJobsResponse } from '../../../models/Jobs';
import Fetcher from '../../../services/Fetcher';
import { locale } from '../../../state/locale/atoms/locale';
import { translate } from '../../../state/locale/selectors/translate';
import { Loading } from '../../reusable/Loading';
import { ParagraphText } from '../../reusable/ParagraphText';
import styles from './Jobs.module.scss';

export const Jobs = () => {
  const i18n = useRecoilValue(translate).jobs;
  const lang = useRecoilValue(locale);
  const { data, isValidating } = useSWR<IJobsResponse>(
    ['/jobs', lang],
    Fetcher.get
  );

  if (!data || isValidating) return <Loading />;

  return (
    <div className={styles.jobsWrapper}>
      <h2 className={styles.pageTitle} id={Ids.rentLocations}>
        {data.title}
      </h2>
      <ParagraphText text={data.description} />
      {Boolean(data.jobOffers.length) && (
        <>
          <h3 className={styles.offersTitle}>{i18n.offers}</h3>
          {data.jobOffers.map((offer) => (
            <div className={styles.offer} key={offer.id.toString()}>
              <h5 className={styles.title}>{offer.title}</h5>
              <h5 className={styles.department}>{offer.department}</h5>
              <ParagraphText text={offer.description} />
              <ParagraphText text={offer.additionalInfo} />
            </div>
          ))}
        </>
      )}
    </div>
  );
};
