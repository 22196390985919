import { Languages } from '../../constants/Languages';
import GetDataService from '../../misc/classes/GetDataService';

class Fetcher extends GetDataService {
  public get = async (path: string, lang?: Languages) => {
    try {
      const data = await fetch(Fetcher.getDataUri(path, lang));

      return data.json();
    } catch (error: any) {
      console.error('Fetcher', error.message);
    }
  };
}

export default new Fetcher();
