import * as React from 'react';
import { useEffect } from 'react';

import cn from 'classnames';
import { useLocation } from 'react-router-dom';
import { useRecoilValue } from 'recoil';
import useSWR from 'swr';

import { Ids } from '../../../constants/Ids';
import scrollToHash from '../../../misc/helpers/scrollToHash';
import { IRentResponse } from '../../../models/Rent';
import Fetcher from '../../../services/Fetcher';
import { locale } from '../../../state/locale/atoms/locale';
import { translate } from '../../../state/locale/selectors/translate';
import { GallerySlider } from '../../reusable/GallerySlider';
import { GoogleMaps } from '../../reusable/GoogleMaps';
import { Link } from '../../reusable/Link';
import { Loading } from '../../reusable/Loading';
import { ParagraphText } from '../../reusable/ParagraphText';
import styles from './Rent.module.scss';

export const Rent = () => {
  const lang = useRecoilValue(locale);
  const i18n = useRecoilValue(translate).rent;
  const location = useLocation();
  const { data, isValidating } = useSWR<IRentResponse>(
    ['/rent', lang],
    Fetcher.get
  );

  useEffect(() => {
    scrollToHash(Boolean(data), location.hash, 500);
  }, [lang, data, location.hash]);

  if (!data || isValidating) return <Loading />;

  return (
    <div className={styles.rentWrapper}>
      <h2 className={styles.pageTitle} id={Ids.rentLocations}>
        {i18n.pageTitle}
      </h2>
      <div className={styles.locationsWrapper}>
        <div className={cn(styles.column, styles.mapContainer)}>
          <GoogleMaps map={data.map} locale={lang} />
        </div>
        <div className={styles.column}>
          <h2 className={styles.pageTitle} id={'locations'}>
            {data.locationsTitle}
          </h2>
          <ParagraphText text={data.locationsDescription} />
        </div>
      </div>
      <GallerySlider
        images={data.gallery.map(
          (image) => process.env.REACT_APP_IMAGE_PREFIX + image.url
        )}
      />
      <h2
        className={cn(styles.paragraph, styles.linkDescription)}
        id={Ids.rentOffers}
      >
        {i18n.linkDescription}
      </h2>
      <Link className={styles.buttonLink} to={data.offersLink} blank={true}>
        {i18n.linkButton}
      </Link>
    </div>
  );
};
