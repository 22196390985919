import axios, { CancelTokenSource } from 'axios';

import GetDataService from '../../misc/classes/GetDataService';
import catchHttpError from '../../misc/helpers/api/catchHttpError';
import getData from '../../misc/helpers/api/getData';
import withCacheHeader from '../../misc/helpers/withCacheHeader';
import { ISinglePageResponse } from './types';

class SinglePageApi extends GetDataService {
  private cancelTokenSinglePage?: CancelTokenSource;

  public cancelSinglePage() {
    if (this.cancelTokenSinglePage) {
      this.cancelTokenSinglePage.cancel();
      this.cancelTokenSinglePage = undefined;
    }
  }

  public getSinglePage(
    locale: string,
    pathname: string,
    slug: string
  ): Promise<ISinglePageResponse> {
    return new Promise((resolve, reject) => {
      this.cancelTokenSinglePage = axios.CancelToken.source();

      axios
        .get(
          SinglePageApi.getDataUri(`/${pathname}/${locale}?slug=${slug}`),
          withCacheHeader({ cancelToken: this.cancelTokenSinglePage.token })
        )
        .then(getData)
        .then((response: ISinglePageResponse) => {
          resolve(response);
        })
        .catch((error) => {
          reject(catchHttpError(error));
        });
    });
  }
}

export default new SinglePageApi();
