import * as React from 'react';
import { useContext, useState } from 'react';

import cn from 'classnames';
import { animated as a } from 'react-spring';

import { AppContext } from '../../_functional/AppContextProvider';
import { MenuContext } from '../../_functional/MenuContextProvider';
import { Brand } from './components/Brand';
import { Burger } from './components/Burger';
import { CollapseMenu } from './components/CollapseMenu';
import { MainMenu } from './components/MainMenu';
import { Toggle } from './components/Toggle';
import styles from './Navbar.module.scss';
import { INavbarProps } from './Navbar.types';

export const Navbar = ({
  isSticky,
  showSticky,
  stickyAnimation,
}: INavbarProps) => {
  const [menuVisible, handleMenu] = useState(false);
  const { navItems } = useContext(MenuContext);
  const { isDesktop } = useContext(AppContext).breakpoints;

  const openMenu = () => {
    handleMenu(!menuVisible);
  };

  return (
    <>
      <a.nav
        className={cn(
          styles.navBar,
          isSticky && styles.sticky,
          isSticky && showSticky && styles.open
        )}
        style={isSticky ? stickyAnimation : undefined}
      >
        {!isDesktop && (
          <Burger
            menuState={menuVisible}
            handleMenu={openMenu}
            invert={isSticky}
          />
        )}
        <Brand invert={isSticky} />
        {isDesktop && <MainMenu menu={navItems} />}
        <Toggle />
      </a.nav>
      <CollapseMenu
        menuState={menuVisible}
        handleMenu={openMenu}
        menu={navItems}
      />
    </>
  );
};

export default Navbar;
