import axios, { CancelTokenSource } from 'axios';

import GetDataService from '../../misc/classes/GetDataService';
import catchHttpError from '../../misc/helpers/api/catchHttpError';
import getData from '../../misc/helpers/api/getData';
import withCacheHeader from '../../misc/helpers/withCacheHeader';
import { IInvestmentsResponse } from './types';

class InvestmentsApi extends GetDataService {
  private cancelTokenInvestments?: CancelTokenSource;

  public cancelInvestments() {
    if (this.cancelTokenInvestments) {
      this.cancelTokenInvestments.cancel();
      this.cancelTokenInvestments = undefined;
    }
  }

  public getInvestments(
    locale: string,
    slug?: string
  ): Promise<IInvestmentsResponse> {
    return new Promise((resolve, reject) => {
      this.cancelTokenInvestments = axios.CancelToken.source();
      const path = slug
        ? `/investments/${locale}?slug=${slug}`
        : `/investments/${locale}`;

      axios
        .get(
          InvestmentsApi.getDataUri(path),
          withCacheHeader({ cancelToken: this.cancelTokenInvestments.token })
        )
        .then(getData)
        .then((response: IInvestmentsResponse) => {
          resolve(response);
        })
        .catch((error) => {
          reject(catchHttpError(error));
        });
    });
  }
}

export default new InvestmentsApi();
