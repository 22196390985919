import * as React from 'react';

import { useRecoilValue } from 'recoil';

import background from '../../../misc/images/backgrounds/background_05.jpg';
import routes from '../../../router/routes';
import { translate } from '../../../state/locale/selectors/translate';
import { ImageSection } from '../../_layout/ImageSection';
import PageWrapper from '../../_layout/PageWrapper';
import { Breadcrumbs } from '../../reusable/Breadcrumbs';
import { Investments } from '../../templates/Investments';

const InvestmentsPage = () => {
  const i18n = useRecoilValue(translate).investments;
  const breadcrumbs = [
    {
      link: routes.investments,
      title: i18n.breadcrumb,
    },
  ];

  return (
    <>
      <ImageSection id={'investments'} image={background} top={true} />
      <PageWrapper>
        <Breadcrumbs base={breadcrumbs} />
        <Investments />
      </PageWrapper>
    </>
  );
};

export default InvestmentsPage;
