import * as React from 'react';
import { useEffect, useState } from 'react';

import { useRecoilValue } from 'recoil';

import fillUrlWithValues from '../../../misc/helpers/fillUrlWithValues';
import routes from '../../../router/routes';
import PressApi from '../../../services/$press-api';
import { IPressResponse } from '../../../services/$press-api/types';
import { locale } from '../../../state/locale/atoms/locale';
import { translate } from '../../../state/locale/selectors/translate';
import { Link } from '../../reusable/Link';
import { Loading } from '../../reusable/Loading';
import styles from './Press.module.scss';

export const Press = () => {
  const lang = useRecoilValue(locale);
  const i18n = useRecoilValue(translate).press;
  const [pressList, setPressList] = useState<IPressResponse | null>(null);

  useEffect(() => {
    PressApi.getPress(lang).then((response) => setPressList(response));
  }, [lang]);

  const pressListPage = pressList?.map((pressElement) => (
    <Link
      className={styles.column}
      to={fillUrlWithValues(
        routes.pressArticles,
        ':category',
        pressElement.slug
      )}
      key={pressElement.id}
    >
      <div className={styles.columnHeader}>
        <img
          className={styles.cover}
          src={process.env.REACT_APP_IMAGE_PREFIX + pressElement.image.url}
          alt={pressElement.image.alternativeText}
        />
      </div>
      <div className={styles.columnBody}>
        <h3 className={styles.columnTitle}>{pressElement.title}</h3>
        <p className={styles.paragraph}>{pressElement.description}</p>
      </div>
    </Link>
  ));

  return (
    <>
      <h2 className={styles.pageTitle}>{i18n.title}</h2>
      {/*<p className={styles.paragraph}>{lang.description}</p>*/}
      <div className={styles.pressWrapper}>
        {pressListPage ? pressListPage : <Loading />}
      </div>
    </>
  );
};
