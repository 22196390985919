import * as React from 'react';

import { useRecoilValue } from 'recoil';

import background from '../../../misc/images/backgrounds/background_10.jpg';
import routes from '../../../router/routes';
import { translate } from '../../../state/locale/selectors/translate';
import { ImageSection } from '../../_layout/ImageSection';
import PageWrapper from '../../_layout/PageWrapper';
import { Breadcrumbs } from '../../reusable/Breadcrumbs';
import { Estate } from '../../templates/Estate';

const EstatePage = () => {
  const i18n = useRecoilValue(translate).estate;
  const breadcrumbs = [
    {
      link: routes.estate,
      title: i18n.breadcrumb,
    },
  ];

  return (
    <>
      <ImageSection id={'estates'} image={background} bottom={true} />
      <PageWrapper>
        <Breadcrumbs base={breadcrumbs} />
        <Estate />
      </PageWrapper>
    </>
  );
};

export default EstatePage;
