const routes = {
  about: '/about',
  contact: '/contact',
  contactSuccess: '/contact/success',
  estate: '/estate',
  fund: '/fund',
  impressum: '/impressum',
  index: '/',
  indexDE: '/de',
  indexEN: '/en',
  indexPL: '/pl',
  investment: '/investments/:slug',
  investments: '/investments',
  jobs: '/jobs',
  policy: '/policy',
  press: '/press',
  pressArticles: '/press/:category',
  purchase: '/purchase',
  rent: '/rent',
  sale: '/sale',
  singlePage: '/pages/:slug',
  sponsorship: '/sponsorship',
  sponsorshipDepartment: '/sponsorship/:slug',
  tenant: '/tenant',
};

export default routes;
