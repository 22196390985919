import * as React from 'react';

import { useRecoilValue } from 'recoil';

import background from '../../../misc/images/backgrounds/background_12.jpg';
import routes from '../../../router/routes';
import { translate } from '../../../state/locale/selectors/translate';
import { ImageSection } from '../../_layout/ImageSection';
import PageWrapper from '../../_layout/PageWrapper';
import { Breadcrumbs } from '../../reusable/Breadcrumbs';
import { Press } from '../../templates/Press';

const PressPage = () => {
  const i18n = useRecoilValue(translate).press;
  const breadcrumbs = [
    {
      link: routes.press,
      title: i18n.breadcrumb,
    },
  ];

  return (
    <>
      <ImageSection id={'press'} image={background} />
      <PageWrapper>
        <Breadcrumbs base={breadcrumbs} />
        <Press />
      </PageWrapper>
    </>
  );
};

export default PressPage;
