import * as React from 'react';

import { useLocation } from 'react-router-dom';
import { useRecoilValue } from 'recoil';

import logo from '../../../misc/images/logos/westminster_crest.svg';
import routes from '../../../router/routes';
import { translate } from '../../../state/locale/selectors/translate';
import PageWrapper from '../../_layout/PageWrapper';
import Breadcrumbs from '../../reusable/Breadcrumbs';
import styles from './Error404.module.scss';

const Error404 = () => {
  const { pathname } = useLocation();
  const i18n = useRecoilValue(translate).errors.error404;
  const breadcrumbs = [
    {
      link: routes.index,
      title: i18n.breadcrumb,
    },
  ];

  return (
    <PageWrapper>
      <Breadcrumbs base={breadcrumbs} />
      <div className={styles.errorWrapper}>
        <img className={styles.crestLogo} src={logo} alt={i18n.text} />
        <h2>{i18n.text}</h2>
        <p>{pathname}</p>
        <p>{i18n.question}</p>
      </div>
    </PageWrapper>
  );
};

export default Error404;
