import * as React from 'react';
import { ChangeEvent } from 'react';

import cn from 'classnames';
import { useRecoilState } from 'recoil';

import { Languages } from '../../../../../constants/Languages';
import { StorageKeys } from '../../../../../constants/StorageKeys';
import { locale } from '../../../../../state/locale/atoms/locale';
import styles from './Toggle.module.scss';

export const Toggle = () => {
  const [lang, setLang] = useRecoilState(locale);
  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value as Languages;
    window.localStorage.setItem(StorageKeys.UserLocale, value);
    setLang(value);
  };

  return (
    <div className={styles.langToggle}>
      <div
        className={cn(
          styles.switch,
          lang === Languages.EN && styles.two,
          lang === Languages.PL && styles.three
        )}
      />
      <label>
        <input
          type={'radio'}
          name={'lang'}
          value={Languages.DE}
          checked={lang === Languages.DE}
          onChange={handleChange}
        />
        <div className={styles.langTag}>DE</div>
      </label>
      <label>
        <input
          type={'radio'}
          name={'lang'}
          value={Languages.EN}
          checked={lang === Languages.EN}
          onChange={handleChange}
        />
        <div className={styles.langTag}>EN</div>
      </label>
      <label>
        <input
          type={'radio'}
          name={'lang'}
          value={Languages.PL}
          checked={lang === 'pl'}
          onChange={handleChange}
        />
        <div className={styles.langTag}>PL</div>
      </label>
    </div>
  );
};

export default Toggle;
