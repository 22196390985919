import * as React from 'react';
import { useState } from 'react';

import { Link } from 'react-router-dom';
import { useRecoilValue } from 'recoil';

import routes from '../../../../../router/routes';
import { translate } from '../../../../../state/locale/selectors/translate';
import styles from './ContactForm.module.scss';

export const ContactForm = () => {
  const i18n = useRecoilValue(translate);
  const [policyChecked, checkPolicy] = useState(false);
  // const submitForm = (event: FormEvent<HTMLFormElement>) => {
  //   // event.preventDefault();
  //   history.push(routes.contactSuccess);
  // }

  const handleCheckbox = () => {
    checkPolicy(!policyChecked);
  };

  return (
    <form
      action={routes.contactSuccess}
      name={'contact'}
      method={'POST'}
      className={styles.contactForm}
      // onSubmit={(event) => submitForm(event)}
    >
      <input type={'hidden'} name={'form-name'} value={'contact'} />
      <label>
        <input
          autoComplete={'off'}
          name={'email'}
          className={styles.inputField}
          type={'email'}
          placeholder={i18n.contact.form.email}
        />
      </label>
      <label>
        <input
          autoComplete={'off'}
          name={'name'}
          className={styles.inputField}
          type={'text'}
          placeholder={i18n.contact.form.name}
        />
      </label>
      <label>
        <textarea
          autoComplete={'off'}
          name={'message'}
          className={styles.inputField}
          rows={6}
          placeholder={i18n.contact.form.message}
        />
      </label>
      <label className={styles.checkbox}>
        <input
          type={'checkbox'}
          onChange={handleCheckbox}
          value={policyChecked.toString()}
          required={true}
        />
        <p>
          {i18n.contact.policy.agreement}&nbsp;
          <Link to={routes.policy}>{i18n.contact.policy.linkTitle}</Link>
        </p>
      </label>
      <button
        className={styles.submitButton}
        type={'submit'}
        disabled={!policyChecked}
      >
        {i18n.contact.form.send}
      </button>
    </form>
  );
};
