import * as React from 'react';
import { createContext, FC } from 'react';

import { useRecoilValue } from 'recoil';
import useSWR from 'swr';

import { IMenuResponse } from '../../../models/Menu';
import Fetcher from '../../../services/Fetcher';
import { locale } from '../../../state/locale/atoms/locale';

const initialState: IMenuResponse = {
  navItems: [],
};

export const MenuContext = createContext(initialState);

export const MenuContextProvider: FC = ({ children }) => {
  const lang = useRecoilValue(locale);
  const { data } = useSWR<IMenuResponse>(['/menu', lang], Fetcher.get);

  if (!data) return <>{children}</>;

  return <MenuContext.Provider value={data} children={children} />;
};
