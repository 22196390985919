import { AxiosRequestConfig } from 'axios';

const isServer = typeof window === 'undefined';

const withCacheHeader = (
  config: AxiosRequestConfig = {}
): AxiosRequestConfig => {
  if (isServer) {
    return {
      ...config,
      headers: {
        ...config.headers,
        'Cache-Control': 'no-cache, no-store, must-revalidate',
      },
    };
  }

  return config;
};

export default withCacheHeader;
