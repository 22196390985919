import * as React from 'react';
import { CSSProperties, FC, useEffect, useRef } from 'react';

import cn from 'classnames';
import { animated as a, useSpring } from 'react-spring';

import getIconClass from '../../../../../misc/helpers/getIconClass';
import useOutsideClick from '../../../../../misc/hooks/useOutsideClick';
import { Link } from '../../../../reusable/Link';
import styles from './CollapseMenu.module.scss';
import { ICollapseMenuProps } from './CollapseMenu.types';

export const CollapseMenu: FC<ICollapseMenuProps> = ({
  menu,
  menuState,
  handleMenu,
}) => {
  const menuRef = useRef(document.createElement('div'));
  const menuAnimation: CSSProperties = useSpring({
    opacity: menuState ? 1 : 0,
    transform: menuState ? 'translate3d(0, 0, 0)' : 'translate3d(-100%, 0, 0)',
  });

  useOutsideClick(menuRef, menuState, handleMenu);

  document.body.style.overflowY = menuState ? 'hidden' : 'scroll';

  useEffect(() => {
    const element = menuRef.current;
    let ts: number;

    element.addEventListener('touchstart', (e) => {
      ts = e.touches[0].clientX;
    });
    element.addEventListener('touchend', (e) => {
      let te = e.changedTouches[0].clientX;
      if (ts > te + 100) {
        handleMenu();
      }
    });

    return () => {
      element.removeEventListener('touchstart', (e) => {
        ts = e.touches[0].clientX;
      });
      element.removeEventListener('touchend', (e) => {
        let te = e.changedTouches[0].clientX;
        if (ts > te + 100) {
          handleMenu();
        }
      });
    };
  });

  return (
    <a.div
      className={styles.collapseMenuWrapper}
      style={menuAnimation}
      ref={menuRef}
    >
      <ul className={styles.navLinks}>
        {menu.map((rootLink, index) => (
          <>
            <li className={styles.link} key={`m-${rootLink.id}-${index}`}>
              <Link
                to={rootLink.link}
                blank={rootLink.newWindow}
                onClick={handleMenu}
              >
                {rootLink.title}
              </Link>
            </li>
            {rootLink.dropdownItems &&
              rootLink.dropdownItems.map((link, index) => (
                <li
                  className={cn(styles.link, styles.dropdownLink)}
                  key={`d-${rootLink.id}-${index}`}
                >
                  <Link to={link.link} blank={true} onClick={handleMenu}>
                    ◦ {link.title}
                  </Link>
                </li>
              ))}
          </>
        ))}
      </ul>
      <div className={styles.social}>
        <Link
          className={cn(getIconClass.facebook, styles.icon)}
          to={'https://www.facebook.com/westminsterimmobilien'}
          onClick={handleMenu}
          blank={true}
        />
        <Link
          className={cn(getIconClass.linkedIn, styles.icon)}
          to={
            'https://www.linkedin.com/company/westminster-unternehmensgruppe/'
          }
          onClick={handleMenu}
          blank={true}
        />
      </div>
    </a.div>
  );
};
