import { MutableRefObject } from 'react';

interface IScrollPosition {
  element?: MutableRefObject<HTMLElement>;
  useWindow?: boolean;
}

const isBrowser = typeof window !== `undefined`;

function getScrollPosition({ element, useWindow }: IScrollPosition) {
  if (!isBrowser) return { x: 0, y: 0 };

  const target = element ? element.current : document.body;
  const position = target?.getBoundingClientRect();

  return useWindow
    ? { x: window.scrollX, y: window.scrollY }
    : { x: position.left, y: position.top };
}

export default getScrollPosition;
