import * as React from 'react';

import cn from 'classnames';

import styles from './Main.module.scss';

export const Main = () => (
  <header className={styles.header}>
    <div className={cn(styles.bgImage, styles.lg)} />
    <div className={styles.headerContent} />
  </header>
);
