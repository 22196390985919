import axios, { CancelTokenSource } from 'axios';

import GetDataService from '../../misc/classes/GetDataService';
import catchHttpError from '../../misc/helpers/api/catchHttpError';
import getData from '../../misc/helpers/api/getData';
import withCacheHeader from '../../misc/helpers/withCacheHeader';
import { ISponsorshipResponse } from './types';

class SponsorshipApi extends GetDataService {
  private cancelTokenSponsorship?: CancelTokenSource;

  public cancelSponsorship() {
    if (this.cancelTokenSponsorship) {
      this.cancelTokenSponsorship.cancel();
      this.cancelTokenSponsorship = undefined;
    }
  }

  public getSponsorship(
    locale: string,
    slug?: string
  ): Promise<ISponsorshipResponse> {
    return new Promise((resolve, reject) => {
      this.cancelTokenSponsorship = axios.CancelToken.source();
      const path = slug
        ? `/sponsoring/${locale}?slug=${slug}`
        : `/sponsoring/${locale}`;

      axios
        .get(
          SponsorshipApi.getDataUri(path),
          withCacheHeader({ cancelToken: this.cancelTokenSponsorship.token })
        )
        .then(getData)
        .then((response: ISponsorshipResponse) => {
          resolve(response);
        })
        .catch((error) => {
          reject(catchHttpError(error));
        });
    });
  }
}

export default new SponsorshipApi();
