import * as React from 'react';

import insertLineBreakMarkups from '../../../misc/helpers/insertLineBreakMarkups';
import getDescriptionTextBlocks from '../../../misc/helpers/splitStringTextBlocks';
import { IParagraphTextProps } from './ParagraphText';
import styles from './ParagraphText.module.scss';

export const ParagraphText = ({ text }: IParagraphTextProps) => {
  const textBlocks = getDescriptionTextBlocks(text);

  return (
    <>
      {textBlocks.map((textBlock, index) => (
        <p key={index.toString()} className={styles.paragraph}>
          {insertLineBreakMarkups(textBlock)}
        </p>
      ))}
    </>
  );
};
