import { Breakpoints } from '../../constants/Breakpoints';

/**
 * Function returns device specific breakpoints depending on the screen width.
 *
 * @param width
 */
export const getBreakpoints = (width: number) => {
  const isDesktop = width > Breakpoints.MD;
  const isMobile = width < Breakpoints.SM;
  const isTablet = !isMobile && !isDesktop;

  return {
    isDesktop,
    isMobile,
    isTablet,
    noDesktop: !isDesktop,
    noMobile: !isMobile,
    noTablet: !isTablet,
  };
};
