import * as React from 'react';

/**
 * function that inserts <br> markups on line breaks
 *
 * @param {string} text
 *
 * @return {ReactNode[]} array of text nodes
 */

const insertLineBreakMarkups = (text: string) => {
  const parts = text.split('\n');

  return parts.map((part, index) => (
    <>
      {part}
      {index + 1 < parts.length && <br />}
    </>
  ));
};

export default insertLineBreakMarkups;
