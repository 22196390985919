import * as React from 'react';
import { useEffect, useState } from 'react';

import cn from 'classnames';
import { Carousel } from 'react-responsive-carousel';
import { useLocation } from 'react-router-dom';
import { useRecoilValue } from 'recoil';

import getIconClass from '../../../misc/helpers/getIconClass';
import insertLineBreakMarkups from '../../../misc/helpers/insertLineBreakMarkups';
import getDescriptionTextBlocks from '../../../misc/helpers/splitStringTextBlocks';
import SinglePageApi from '../../../services/$singlePage-api';
import { useCurrentBreadcrumb } from '../../../state/breadcrumbs/hooks/useCurrentBreadrumb';
import { locale } from '../../../state/locale/atoms/locale';
import { translate } from '../../../state/locale/selectors/translate';
import { Link } from '../../reusable/Link';
import { Loading } from '../../reusable/Loading';
import styles from './SinglePage.module.scss';
import { ISinglePage } from './SinglePage.types';

export const SinglePage = () => {
  const lang = useRecoilValue(locale);
  const { pathname } = useLocation();
  const i18n = useRecoilValue(translate).investments.page;
  const [page, setPage] = useState<ISinglePage | null>(null);
  const textBlocks = page?.description
    ? getDescriptionTextBlocks(page.description)
    : [''];
  const path = pathname.split('/', 3)[1];
  const slug = pathname.split('/', 3)[2];
  useCurrentBreadcrumb(page?.title);

  useEffect(() => {
    SinglePageApi.getSinglePage(lang, path, slug).then((response) =>
      setPage(response[0])
    );
  }, [lang, path, slug]);

  return page ? (
    <div className={styles.singlePageWrapper}>
      <Carousel
        autoPlay={true}
        dynamicHeight={true}
        emulateTouch={true}
        infiniteLoop={true}
        interval={5000}
        showArrows={true}
        showIndicators={true}
        showStatus={false}
        showThumbs={false}
        swipeable={true}
        transitionTime={500}
        useKeyboardArrows={false}
        className={styles.carousel}
      >
        {page.images.map((image, index) => (
          <div className={styles.imageContainer} key={index.toString()}>
            <img
              className={styles.image}
              src={process.env.REACT_APP_IMAGE_PREFIX + image.url}
              alt={`Westminster ${index}`}
            />
          </div>
        ))}
      </Carousel>
      <h2 className={styles.pageTitle}>{page.title}</h2>
      {textBlocks.map((textBlock, index) => (
        <p key={index.toString()} className={styles.paragraph}>
          {insertLineBreakMarkups(textBlock)}
        </p>
      ))}
      {page.websiteLink && (
        <Link className={styles.buttonLink} to={page.websiteLink} blank={true}>
          {i18n.button}
        </Link>
      )}
      <div className={styles.social}>
        {page.facebookLink && (
          <Link
            className={cn(styles.icon, getIconClass.facebook)}
            to={page.facebookLink}
            blank={true}
          />
        )}
        {page.instagramLink && (
          <Link
            className={cn(styles.icon, getIconClass.instagram)}
            to={page.instagramLink}
            blank={true}
          />
        )}
      </div>
    </div>
  ) : (
    <Loading />
  );
};
