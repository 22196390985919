import * as React from 'react';

import GoogleMapReact from 'google-map-react';

import Marker from './components/Marker';
import { mapStyles } from './GoogleMaps.styles';
import { IGoogleMapsProps } from './GoogleMaps.types';

export const GoogleMaps = ({ locale, map }: IGoogleMapsProps) => {
  const apiKey = process.env.REACT_APP_GOOGLE_MAPS_API_KEY || '';

  const center = {
    lat: map.centerLat,
    lng: map.centerLng,
  };

  const markerList = map.markers.map((marker) => (
    <Marker
      lat={marker.lat}
      lng={marker.lng}
      text={marker.title}
      key={marker.id}
    />
  ));

  return (
    <GoogleMapReact
      bootstrapURLKeys={{ key: apiKey, language: locale, region: locale }}
      center={center}
      options={{ styles: mapStyles }}
      zoom={map.zoom}
    >
      {markerList}
    </GoogleMapReact>
  );
};
