import * as React from 'react';
import { FC } from 'react';

import cn from 'classnames';
import { useInView } from 'react-intersection-observer';

import styles from './ImageSection.module.scss';
import { IImageSectionOwnProps } from './ImageSection.types';

export const ImageSection: FC<IImageSectionOwnProps> = ({
  bottom,
  id,
  image,
  top,
}) => {
  const [ref, inView] = useInView({ threshold: 0.9 });

  return (
    <div className={styles.imageSectionWrapper} id={id} ref={ref}>
      <img
        className={cn(
          styles.image,
          inView && styles.inView,
          bottom && styles.bottom,
          top && styles.top
        )}
        src={image}
        alt={'Westminster'}
      />
    </div>
  );
};
