import * as React from 'react';
import { CSSProperties } from 'react';

import { animated as a, config, useSpring } from 'react-spring';
import { useRecoilValue } from 'recoil';

import { Languages } from '../../../../../constants/Languages';
import logo_de from '../../../../../misc/images/logos/westminster_logo_de.svg';
import logo_pl from '../../../../../misc/images/logos/westminster_logo_pl.svg';
import { locale } from '../../../../../state/locale/atoms/locale';
import styles from './Brand.module.scss';
import { IBrandProps } from './Brand.types';

const LOGO_ALT_TEXT = 'Westminster';

export const Brand = ({ invert }: IBrandProps) => {
  const lang = useRecoilValue(locale);
  const logoAnimation: CSSProperties = useSpring({
    from: { opacity: 0 },
    to: { opacity: 1 },
    delay: 500,
    config: config.molasses,
  });

  return (
    <h1
      className={styles.logoContainer}
      itemScope={true}
      itemType={'http://schema.org/Organization'}
    >
      <a.a
        className={styles.logoLink}
        href={'/'}
        style={logoAnimation}
        itemProp={'url'}
      >
        <img
          className={styles.logo}
          src={lang === Languages.PL ? logo_pl : logo_de}
          alt={LOGO_ALT_TEXT}
          itemProp={'logo'}
        />
      </a.a>
    </h1>
  );
};

export default Brand;
