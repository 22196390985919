import * as React from 'react';

import { useRecoilValue } from 'recoil';

import { Ids } from '../../../constants/Ids';
import background from '../../../misc/images/backgrounds/background_10.jpg';
import routes from '../../../router/routes';
import { translate } from '../../../state/locale/selectors/translate';
import { ImageSection } from '../../_layout/ImageSection';
import PageWrapper from '../../_layout/PageWrapper';
import Breadcrumbs from '../../reusable/Breadcrumbs';
import { Loading } from '../../reusable/Loading';
import { Purchase } from '../../templates/Purchase';

const PurchaseScreen = () => {
  const i18n = useRecoilValue(translate).purchase;
  const breadcrumbs = [
    {
      link: routes.purchase,
      title: i18n.breadcrumb,
    },
  ];

  return (
    <React.Suspense fallback={<Loading />}>
      <ImageSection id={Ids.property} image={background} />
      <PageWrapper>
        <Breadcrumbs base={breadcrumbs} />
        <Purchase />
      </PageWrapper>
    </React.Suspense>
  );
};

export default PurchaseScreen;
