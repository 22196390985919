import * as React from 'react';
import { FC } from 'react';

import cn from 'classnames';

import styles from './Burger.module.scss';
import { IBurgerProps } from './Burger.types';

export const Burger: FC<IBurgerProps> = ({ menuState, handleMenu, invert }) => (
  <div className={styles.burgerWrapper} onClick={handleMenu}>
    <div className={cn(menuState && styles.open, invert && styles.invert)}>
      <span />
      <span />
      <span />
    </div>
  </div>
);
