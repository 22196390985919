import * as React from 'react';

import { useRecoilValue } from 'recoil';

import background from '../../../misc/images/backgrounds/background_08.jpg';
import routes from '../../../router/routes';
import { translate } from '../../../state/locale/selectors/translate';
import { ImageSection } from '../../_layout/ImageSection';
import PageWrapper from '../../_layout/PageWrapper';
import { Breadcrumbs } from '../../reusable/Breadcrumbs';
import { Contact } from '../../templates/Contact';

const ContactPage = () => {
  const i18n = useRecoilValue(translate).contact;
  const breadcrumbs = [
    {
      link: routes.contact,
      title: i18n.breadcrumb,
    },
  ];

  return (
    <>
      <ImageSection id={'contact'} image={background} />
      <PageWrapper>
        <Breadcrumbs base={breadcrumbs} />
        <Contact />
      </PageWrapper>
    </>
  );
};

export default ContactPage;
