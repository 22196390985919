import { selector } from 'recoil';

import { Languages } from '../../../constants/Languages';
import { RecoilKeys } from '../../../constants/RcoilKeys';
import { de } from '../../../lang/de';
import { en } from '../../../lang/en';
import { pl } from '../../../lang/pl';
import { ITranslation } from '../../../models/Locale';
import { locale } from '../atoms/locale';

export const translate = selector<ITranslation>({
  get: ({ get }) => {
    const language = get(locale);

    switch (language) {
      case Languages.PL:
        return pl;
      case Languages.DE:
        return de;
      case Languages.EN:
        return en;
      default:
        return pl;
    }
  },
  key: RecoilKeys.translate,
});
