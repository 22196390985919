import * as React from 'react';

import { useRecoilValue } from 'recoil';

import routes from '../../../router/routes';
import { translate } from '../../../state/locale/selectors/translate';
import PageWrapper from '../../_layout/PageWrapper';
import Breadcrumbs from '../../reusable/Breadcrumbs';
import { SinglePage } from '../../templates/SinglePage';

const InvestmentPage = () => {
  const i18n = useRecoilValue(translate).investments;
  const breadcrumbs = [
    {
      link: routes.investments,
      title: i18n.breadcrumb,
    },
  ];

  return (
    <PageWrapper>
      <Breadcrumbs base={breadcrumbs} />
      <SinglePage />
    </PageWrapper>
  );
};

export default InvestmentPage;
