const HOME = 'icon-home';
const PRINTER = 'icon-printer';
const PHONE = 'icon-phone';
const LOCATION = 'icon-location2';
const BUBBLE = 'icon-bubble';
const SPINNER = 'icon-spinner';
const SPHERE = 'icon-sphere';
const WARNING = 'icon-warning';
const CHECK = 'icon-checkmark2';
const FACEBOOK = 'icon-facebook2';
const INSTAGRAM = 'icon-instagram';
const LINKEDIN = 'icon-linkedin';

const icons = {
  home: HOME,
  printer: PRINTER,
  phone: PHONE,
  location: LOCATION,
  bubble: BUBBLE,
  spinner: SPINNER,
  sphere: SPHERE,
  warning: WARNING,
  check: CHECK,
  facebook: FACEBOOK,
  instagram: INSTAGRAM,
  linkedIn: LINKEDIN,
};

export default icons;
