import { ITranslation } from '../models/Locale';

export const pl: ITranslation = {
  about: {
    breadcrumb: 'O nas',
  },
  articles: {
    estate: {
      breadcrumb: 'Nieruchomości',
    },
    sponsorship: {
      breadcrumb: 'Sponsoring',
    },
  },
  department: {
    button: 'Przejdź do strony',
  },
  estate: {
    breadcrumb: 'Nieruchomości',
    proposition:
      'Masz dla nas ciekawą ofertę na zakup nieruchomości? Porozmawiaj z nami. Z przyjemnością odpowiemy na Twoje pytania.',
    linkButton: 'Przejdź do strony',
    linkDescription:
      'Jeśli jesteś zainteresowany zakupem lub wynajmem naszej nieruchomości, wszystkie aktualne oferty znajdziesz tutaj:',
  },
  fund: {
    breadcrumb: 'Fundusz inwestycyjny',
    pageTitle: 'Fundusz inwestycyjny',
  },
  investments: {
    breadcrumb: 'Inwestycje',
    page: {
      button: 'Przejdź do strony',
    },
  },
  jobs: {
    breadcrumb: 'Praca & Kariera',
    pageTitle: 'Praca & Kariera',
    offers: 'Obecnie poszukujemy pracowników w następujących obszarach',
  },
  purchase: {
    breadcrumb: 'Zakup',
    pageTitle: 'Zakup',
  },
  rent: {
    breadcrumb: 'Wynajem',
    pageTitle: 'Wynajem',
    linkButton: 'Przejdź do strony',
    linkDescription:
      'Jeśli jesteś zainteresowany zakupem lub wynajmem naszej nieruchomości, wszystkie aktualne oferty znajdziesz tutaj:',
    tenantApplication: 'Formularz najemcy',
    tenantServiceTitle: 'Obsługa najemcy',
  },
  sale: {
    breadcrumb: 'Sprzedaż',
    pageTitle: 'Sprzedaż',
  },
  sponsorship: {
    breadcrumb: 'Sponsoring',
    title: 'Działalność sponsorska',
    description:
      'Chocolate croissant dessert soufflé tootsie roll. Ice cream topping pastry. Croissant jujubes lollipop cookie. Brownie chocolate bar muffin apple pie marshmallow chupa chups. Cupcake sesame snaps oat cake fruitcake sweet jelly cake. Caramels candy canes gummies macaroon bonbon. Marzipan caramels oat cake jujubes.',
  },
  pages: {
    breadcrumb: 'Strona',
  },
  press: {
    breadcrumb: 'Prasa',
    description:
      'Muffin bear claw icing candy. Powder ice cream danish marshmallow danish chupa chups. Sugar plum chocolate cake bear claw cotton candy oat cake cheesecake.',
    title: 'Publikacje prasowe',
  },
  contact: {
    breadcrumb: 'Kontakt',
    form: {
      email: 'E-mail',
      message: 'Wiadomość',
      name: 'Imię i nazwisko',
      send: 'Wyślij',
    },
    success: {
      breadcrumb: 'Kontakt',
      button: 'Powrót',
      message: 'Dziękujemy, otrzymaliśmy Twoją wiadomość!',
    },
    policy: {
      agreement: 'Przeczytałem/am i akceptuję',
      impressumLink: '/pdf/impressum_pl.pdf',
      link: '/pdf/privacy_policy_pl.pdf',
      linkTitle: 'Politykę prywatności',
    },
  },
  errors: {
    error404: {
      breadcrumb: '404 Strony nie znaleziono',
      question: 'Czy to na pewno poprawny adres?',
      text: 'Strona o podanym adresie nie istnieje.',
    },
  },
  tenant: {
    breadcrumb: 'Sekcja najemcy',
    pageTitle: 'Sekcja najemcy',
  },
};
