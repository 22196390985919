import axios, { CancelTokenSource } from 'axios';

import GetDataService from '../../misc/classes/GetDataService';
import catchHttpError from '../../misc/helpers/api/catchHttpError';
import getData from '../../misc/helpers/api/getData';
import withCacheHeader from '../../misc/helpers/withCacheHeader';
import { IArticleResponse } from './types';

class ArticleApi extends GetDataService {
  private cancelTokenArticle?: CancelTokenSource;

  public cancelArticle() {
    if (this.cancelTokenArticle) {
      this.cancelTokenArticle.cancel();
      this.cancelTokenArticle = undefined;
    }
  }

  public getArticles(locale: string, slug: string): Promise<IArticleResponse> {
    return new Promise((resolve, reject) => {
      this.cancelTokenArticle = axios.CancelToken.source();

      axios
        .get(
          ArticleApi.getDataUri(`/press/${locale}?slug=${slug}`),
          withCacheHeader({ cancelToken: this.cancelTokenArticle.token })
        )
        .then(getData)
        .then((response: IArticleResponse[]) => {
          resolve(response[0]);
        })
        .catch((error) => {
          reject(catchHttpError(error));
        });
    });
  }
}

export default new ArticleApi();
