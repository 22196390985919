import axios, { CancelTokenSource } from 'axios';

import GetDataService from '../../misc/classes/GetDataService';
import catchHttpError from '../../misc/helpers/api/catchHttpError';
import getData from '../../misc/helpers/api/getData';
import withCacheHeader from '../../misc/helpers/withCacheHeader';
import { IEstateResponse } from './types';

class EstateApi extends GetDataService {
  private cancelTokenEstate?: CancelTokenSource;

  public cancelEstate() {
    if (this.cancelTokenEstate) {
      this.cancelTokenEstate.cancel();
      this.cancelTokenEstate = undefined;
    }
  }

  public getEstatePage(locale: string): Promise<IEstateResponse> {
    return new Promise((resolve, reject) => {
      this.cancelTokenEstate = axios.CancelToken.source();

      axios
        .get(
          EstateApi.getDataUri(`/estate/${locale}`),
          withCacheHeader({ cancelToken: this.cancelTokenEstate.token })
        )
        .then(getData)
        .then((response: IEstateResponse) => {
          resolve(response);
        })
        .catch((error) => {
          reject(catchHttpError(error));
        });
    });
  }
}

export default new EstateApi();
