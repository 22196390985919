import { atom } from 'recoil';

import { Languages } from '../../../constants/Languages';
import { RecoilKeys } from '../../../constants/RcoilKeys';
import { StorageKeys } from '../../../constants/StorageKeys';

const savedLocale = window.localStorage.getItem(StorageKeys.UserLocale);

export const locale = atom<Languages>({
  default: (savedLocale as Languages) ?? Languages.PL,
  key: RecoilKeys.locale,
});
