import * as React from 'react';

import getIconClass from '../../../misc/helpers/getIconClass';
import { IAddressProps } from './Address';
import styles from './Address.module.scss';

export const Address = ({ address }: IAddressProps) => {
  return (
    <div className={styles.address} key={address.id}>
      <p>{address.street}</p>
      <p>
        {address.postcode} {address.city}
      </p>
      <p>
        <span className={getIconClass.phone} /> {address.phone}
      </p>
      {address.fax && (
        <p>
          <span className={getIconClass.printer} /> {address.fax}
        </p>
      )}
      <a className={styles.mailLink} href={`mailto:${address.email}`}>
        {address.email}
      </a>
    </div>
  );
};
