import * as React from 'react';

import { Link } from '../Link';
import styles from './HorizontalCard.module.scss';
import { IHorizontalCardProps } from './HorizontalCard.types';

export const HorizontalCard = ({
  date,
  description,
  link,
  linkBlank,
  id,
  image,
  linkText,
  publisher,
  title,
}: IHorizontalCardProps) => (
  <Link className={styles.horizontalCardWrapper} to={link} blank={linkBlank}>
    <div className={styles.imageContainer} id={id}>
      <img src={image} className={styles.image} alt={title} />
    </div>
    <div className={styles.cardBody}>
      {date && publisher && (
        <p className={styles.data}>
          {date} ✑ <b>{publisher}</b>
        </p>
      )}
      <h2 className={styles.title}>{title}</h2>
      <p className={styles.description}>{description}</p>
      {linkText && <p className={styles.linkText}>{linkText}</p>}
    </div>
  </Link>
);
