import * as React from 'react';
import { useEffect, useState } from 'react';

import { useRecoilValue } from 'recoil';

import fillUrlWithValues from '../../../misc/helpers/fillUrlWithValues';
import routes from '../../../router/routes';
import SponsorshipApi from '../../../services/$sponsorship-api';
import { ISponsorshipResponse } from '../../../services/$sponsorship-api/types';
import { locale } from '../../../state/locale/atoms/locale';
import { translate } from '../../../state/locale/selectors/translate';
import { Link } from '../../reusable/Link';
import { Loading } from '../../reusable/Loading';
import styles from './Sponsorship.module.scss';

export const Sponsorship = () => {
  const [list, setList] = useState<ISponsorshipResponse | null>(null);
  const lang = useRecoilValue(locale);
  const i18n = useRecoilValue(translate).sponsorship;

  useEffect(() => {
    SponsorshipApi.getSponsorship(lang).then((response) => setList(response));
  }, [lang]);

  const departmentList = list?.map((department) => (
    <Link
      className={styles.column}
      to={fillUrlWithValues(
        routes.sponsorshipDepartment,
        ':slug',
        department.slug
      )}
      key={department.id}
    >
      <div className={styles.columnHeader}>
        <img
          className={styles.cover}
          src={process.env.REACT_APP_IMAGE_PREFIX + department.image.url}
          alt={department.title}
        />
      </div>
      <div className={styles.columnBody}>
        <h3 className={styles.columnTitle}>{department.title}</h3>
        <p className={styles.paragraph}>{department.description}</p>
      </div>
    </Link>
  ));

  return (
    <>
      <h2 className={styles.pageTitle}>{i18n.title}</h2>
      {/*<p className={styles.paragraph}>{lang.description}</p>*/}
      <div className={styles.sponsorshipWrapper}>
        {departmentList ? departmentList : <Loading />}
      </div>
    </>
  );
};
