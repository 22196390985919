import * as React from 'react';

import { useRecoilValue } from 'recoil';
import useSWR from 'swr';

import insertLineBreakMarkups from '../../../misc/helpers/insertLineBreakMarkups';
import getDescriptionTextBlocks from '../../../misc/helpers/splitStringTextBlocks';
import { IAboutResponse } from '../../../models/About';
import Fetcher from '../../../services/Fetcher';
import { locale } from '../../../state/locale/atoms/locale';
import { Loading } from '../../reusable/Loading';
import styles from './About.module.scss';

export const About = () => {
  const lang = useRecoilValue(locale);
  const { data, isValidating } = useSWR<IAboutResponse>(
    ['/about', lang],
    Fetcher.get
  );

  if (!data || isValidating) return <Loading />;

  const textBlocks = getDescriptionTextBlocks(data.description);

  return (
    <div className={styles.aboutWrapper}>
      <h2 className={styles.pageTitle}>{data.title}</h2>
      {textBlocks.map((textBlock, index) => (
        <p key={index.toString()} className={styles.paragraph}>
          {insertLineBreakMarkups(textBlock)}
        </p>
      ))}
    </div>
  );
};
