import { useCallback, useEffect, useState } from 'react';

import debounce from 'lodash.debounce';
import ResizeObserver from 'resize-observer-polyfill';

interface IDimensions {
  height: number;
  width: number;
}

export const useResizer = () => {
  const [dimensions, setDimension] = useState<IDimensions>({
    height: window.innerHeight,
    width: window.innerWidth,
  });
  const resizeObserver = new ResizeObserver((entries) => {
    for (const entry of entries) {
      onResize({
        height: entry.contentRect.height,
        width: entry.contentRect.width,
      });
    }
  });

  const onResize = (dimensions: IDimensions) => {
    debounceSetDimensions(dimensions);
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debounceSetDimensions = useCallback(debounce(setDimension, 200), []);

  useEffect(() => {
    resizeObserver.observe(document.body);

    return () => resizeObserver.unobserve(document.body);
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return {
    height: dimensions.height,
    width: dimensions.width,
  };
};
