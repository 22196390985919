import { useSetRecoilState } from 'recoil';

import { Languages } from '../../../constants/Languages';
import { StorageKeys } from '../../../constants/StorageKeys';
import { useLayoutEffect } from '../../../misc/hooks/useIsomorphicLayoutEffect';
import { locale } from '../atoms/locale';

export const useUserLocale = () => {
  const setLocale = useSetRecoilState(locale);

  // @TODO: add internet explorer support
  const getBrowserLocales = (options = {}) => {
    const defaultOptions = {
      languageCodeOnly: false,
    };
    const opt = {
      ...defaultOptions,
      ...options,
    };
    const browserLocales =
      navigator.languages === undefined
        ? [navigator.language]
        : navigator.languages;
    if (!browserLocales) {
      return undefined;
    }
    return browserLocales.map((locale) => {
      const trimmedLocale = locale.trim();
      return opt.languageCodeOnly
        ? trimmedLocale.split(/-|_/)[0]
        : trimmedLocale;
    });
  };

  useLayoutEffect(() => {
    const savedLocale = window.localStorage.getItem(StorageKeys.UserLocale);
    if (savedLocale) {
      const userLocale = savedLocale as Languages;
      if (Object.values(Languages).includes(userLocale)) {
        setLocale(userLocale);
      }
    } else {
      const browserLocales = getBrowserLocales({
        languageCodeOnly: true,
      });

      if (browserLocales) {
        const browserLocale = browserLocales[0] as Languages;

        if (Object.values(Languages).includes(browserLocale)) {
          setLocale(browserLocale);
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
};
