import * as React from 'react';

import { useRecoilValue } from 'recoil';

import background from '../../../misc/images/backgrounds/background_03.jpg';
import routes from '../../../router/routes';
import { translate } from '../../../state/locale/selectors/translate';
import { ImageSection } from '../../_layout/ImageSection';
import PageWrapper from '../../_layout/PageWrapper';
import { Breadcrumbs } from '../../reusable/Breadcrumbs';
import { About } from '../../templates/About';

const AboutPage = () => {
  const i18n = useRecoilValue(translate).about;
  const breadcrumbs = [
    {
      link: routes.about,
      title: i18n.breadcrumb,
    },
  ];

  return (
    <>
      <ImageSection id={'about'} image={background} />
      <PageWrapper>
        <Breadcrumbs base={breadcrumbs} />
        <About />
      </PageWrapper>
    </>
  );
};

export default AboutPage;
