import * as React from 'react';

import { useRecoilValue } from 'recoil';

import routes from '../../../router/routes';
import { translate } from '../../../state/locale/selectors/translate';
import PageWrapper from '../../_layout/PageWrapper';
import Breadcrumbs from '../../reusable/Breadcrumbs';
import { Department } from '../../templates/Department';

const DepartmentPage = () => {
  const i18n = useRecoilValue(translate).sponsorship;
  const breadcrumbs = [
    {
      link: routes.sponsorship,
      title: i18n.breadcrumb,
    },
  ];

  return (
    <>
      <PageWrapper>
        <Breadcrumbs base={breadcrumbs} />
        <Department />
      </PageWrapper>
    </>
  );
};

export default DepartmentPage;
