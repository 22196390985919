import * as React from 'react';

import { useRecoilValue } from 'recoil';

import background from '../../../misc/images/backgrounds/background_08.jpg';
import routes from '../../../router/routes';
import { translate } from '../../../state/locale/selectors/translate';
import { ImageSection } from '../../_layout/ImageSection';
import PageWrapper from '../../_layout/PageWrapper';
import { Breadcrumbs } from '../../reusable/Breadcrumbs';
import { Link } from '../../reusable/Link';
import styles from './ContactSuccess.module.scss';

const ContactSuccess = () => {
  const i18n = useRecoilValue(translate).contact.success;
  const breadcrumbs = [
    {
      link: routes.contact,
      title: i18n.breadcrumb,
    },
  ];

  return (
    <>
      <ImageSection id={'contact'} image={background} />
      <PageWrapper>
        <Breadcrumbs base={breadcrumbs} />
        <div className={styles.messageWrapper}>
          <p>{i18n.message}</p>
          <Link to={routes.contact} className={styles.backButton}>
            {i18n.button}
          </Link>
        </div>
      </PageWrapper>
    </>
  );
};

export default ContactSuccess;
