import axios, { CancelTokenSource } from 'axios';

import GetDataService from '../../misc/classes/GetDataService';
import catchHttpError from '../../misc/helpers/api/catchHttpError';
import getData from '../../misc/helpers/api/getData';
import withCacheHeader from '../../misc/helpers/withCacheHeader';
import { IPressResponse } from './types';

class PressApi extends GetDataService {
  private cancelTokenPress?: CancelTokenSource;

  public cancelPress() {
    if (this.cancelTokenPress) {
      this.cancelTokenPress.cancel();
      this.cancelTokenPress = undefined;
    }
  }

  public getPress(locale: string): Promise<IPressResponse> {
    return new Promise((resolve, reject) => {
      this.cancelTokenPress = axios.CancelToken.source();

      axios
        .get(
          PressApi.getDataUri(`/press/${locale}`),
          withCacheHeader({ cancelToken: this.cancelTokenPress.token })
        )
        .then(getData)
        .then((response: IPressResponse) => {
          resolve(response);
        })
        .catch((error) => {
          reject(catchHttpError(error));
        });
    });
  }
}

export default new PressApi();
