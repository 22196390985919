import * as React from 'react';
import { useEffect, useState } from 'react';

import { useRecoilValue } from 'recoil';

import fillUrlWithValues from '../../../misc/helpers/fillUrlWithValues';
import routes from '../../../router/routes';
import InvestmentsApi from '../../../services/$investments-api';
import { IInvestmentsResponse } from '../../../services/$investments-api/types';
import { locale } from '../../../state/locale/atoms/locale';
import { translate } from '../../../state/locale/selectors/translate';
import { HorizontalCard } from '../../reusable/HorizontalCard';
import { Loading } from '../../reusable/Loading';
import styles from './Investments.module.scss';

export const Investments = () => {
  const i18n = useRecoilValue(translate).investments.page;
  const lang = useRecoilValue(locale);
  const [investments, setInvestments] = useState<IInvestmentsResponse | null>(
    null
  );

  useEffect(() => {
    InvestmentsApi.getInvestments(lang).then((response) =>
      setInvestments(response)
    );
  }, [lang]);

  const investmentsList = investments?.map((investment) => (
    <HorizontalCard
      description={investment.description}
      link={fillUrlWithValues(routes.investment, ':slug', investment.slug)}
      image={process.env.REACT_APP_IMAGE_PREFIX + investment.images[0].url}
      title={investment.title}
      linkText={i18n.button}
      key={investment.slug}
    />
  ));

  return investments ? (
    <div className={styles.investmentsWrapper}>{investmentsList}</div>
  ) : (
    <Loading />
  );
};
