import * as React from 'react';

import { useRecoilValue } from 'recoil';

import background from '../../../misc/images/backgrounds/background_03.jpg';
import routes from '../../../router/routes';
import { translate } from '../../../state/locale/selectors/translate';
import { ImageSection } from '../../_layout/ImageSection';
import PageWrapper from '../../_layout/PageWrapper';
import { Breadcrumbs } from '../../reusable/Breadcrumbs';
import { Jobs } from '../../templates/Jobs';

const JobsPage = () => {
  const i18n = useRecoilValue(translate).jobs;
  const breadcrumbs = [
    {
      link: routes.jobs,
      title: i18n.breadcrumb,
    },
  ];

  return (
    <>
      <ImageSection id={'jobs'} image={background} />
      <PageWrapper>
        <Breadcrumbs base={breadcrumbs} />
        <Jobs />
      </PageWrapper>
    </>
  );
};

export default JobsPage;
