/**
 * Function that replaces the keys in the URL with the corresponding values
 *
 * @param {string} url - Source url.
 * @param {string[] | string} keys - Key or Keys to be replaced (sugested with prefix `:` but is not required).
 * @param {string[] | string} values - Values for coresponding keys.
 * @return {string} Destination url.
 */

const fillUrlWithValues = (
  url: string,
  keys: string[] | string,
  values: string[] | string
) => {
  const arrayKeys = typeof keys === 'string' ? [keys] : keys;
  const arrayValues = typeof values === 'string' ? [values] : values;

  let replacedUrl = url;

  for (let i = 0; i < arrayKeys.length; i++) {
    if (arrayValues[i] !== undefined) {
      replacedUrl = replacedUrl.replace(arrayKeys[i], arrayValues[i]);
    }
  }

  return replacedUrl;
};

export default fillUrlWithValues;
