import * as React from 'react';

import { render } from 'react-dom';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import { BrowserRouter } from 'react-router-dom';
import { RecoilRoot } from 'recoil';
import 'reset-css';

import './misc/styles/global.module.scss';
import './misc/styles/icons/icomoon.scss';
import { Router } from './router';
import { AppContextProvider } from './ui/_functional/AppContextProvider';
import { MenuContextProvider } from './ui/_functional/MenuContextProvider';
import { Grid } from './ui/_layout/Grid';

render(
  <RecoilRoot>
    <AppContextProvider>
      <MenuContextProvider>
        <BrowserRouter>
          <Grid>
            <Router />
          </Grid>
        </BrowserRouter>
      </MenuContextProvider>
    </AppContextProvider>
  </RecoilRoot>,
  document.getElementById('root')
);
