import * as React from 'react';

import { useRecoilValue } from 'recoil';
import useSWR from 'swr';

import Fetcher from '../../../services/Fetcher';
import { locale } from '../../../state/locale/atoms/locale';
import { Markdown } from '../../molecues/Markdown';
import { Loading } from '../../reusable/Loading';

export const ImpressumScreen = () => {
  const lang = useRecoilValue(locale);
  const { data } = useSWR(['/impressum', lang], Fetcher.get);

  if (!data) return <Loading />;

  return <Markdown source={data.impressum} />;
};
