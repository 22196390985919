import * as React from 'react';
import { useContext } from 'react';

import { useRecoilValue } from 'recoil';

import { Languages } from '../../../constants/Languages';
import crest_de from '../../../misc/images/logos/westminster_logo_vert_de.svg';
import crest_pl from '../../../misc/images/logos/westminster_logo_vert_pl.svg';
import { locale } from '../../../state/locale/atoms/locale';
import { MenuContext } from '../../_functional/MenuContextProvider';
import { Link } from '../../reusable/Link';
import styles from './Footer.module.scss';

const COPYRIGHT_TEXT = '© 2020 orb2 All Rights Reserved';

export const Footer = () => {
  const { navItems } = useContext(MenuContext);
  const lang = useRecoilValue(locale);

  const scrollOnClick = () => {
    window.scrollTo(0, 0);
  };

  return (
    <footer className={styles.footer}>
      <div className={styles.footerRow}>
        <ul className={styles.footerMenu}>
          {navItems.map((mainLink) => (
            <li className={styles.footerColumn} key={mainLink.id}>
              <Link
                className={styles.mainLink}
                to={mainLink.link}
                blank={mainLink.newWindow}
                onClick={() => scrollOnClick()}
              >
                {mainLink.title}
              </Link>
              {mainLink.dropdownItems?.map((link) => (
                <Link
                  className={styles.subLink}
                  to={link.link}
                  blank={link.newWindow}
                  key={link.id}
                  onClick={() => scrollOnClick()}
                >
                  {link.title}
                </Link>
              ))}
            </li>
          ))}
        </ul>
        <div className={styles.rightColumn}>
          <img
            className={styles.crest}
            src={lang === Languages.PL ? crest_pl : crest_de}
            alt={'Westminster'}
          />
        </div>
      </div>
      <a
        className={styles.copyrights}
        href={'https://orb2.space'}
        rel={'dofollow'}
      >
        {COPYRIGHT_TEXT}
      </a>
    </footer>
  );
};
