import * as React from 'react';
import { FC } from 'react';

import styles from './PageWrapper.module.scss';

const PageWrapper: FC = ({ children }) => (
  <div className={styles.pageWrapper}>{children}</div>
);

export default PageWrapper;
