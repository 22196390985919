import axios, { CancelTokenSource } from 'axios';

import GetDataService from '../../misc/classes/GetDataService';
import catchHttpError from '../../misc/helpers/api/catchHttpError';
import getData from '../../misc/helpers/api/getData';
import withCacheHeader from '../../misc/helpers/withCacheHeader';
import { IContactResponse } from './types';

class ContactApi extends GetDataService {
  private cancelTokenContact?: CancelTokenSource;

  public cancelContact() {
    if (this.cancelTokenContact) {
      this.cancelTokenContact.cancel();
      this.cancelTokenContact = undefined;
    }
  }

  public getContactPage(locale: string): Promise<IContactResponse> {
    return new Promise((resolve, reject) => {
      this.cancelTokenContact = axios.CancelToken.source();

      axios
        .get(
          ContactApi.getDataUri(`/contact/${locale}`),
          withCacheHeader({ cancelToken: this.cancelTokenContact.token })
        )
        .then(getData)
        .then((response: IContactResponse) => {
          resolve(response);
        })
        .catch((error) => {
          reject(catchHttpError(error));
        });
    });
  }
}

export default new ContactApi();
