import * as React from 'react';

import { useRecoilValue } from 'recoil';

import background from '../../../misc/images/backgrounds/background_10.jpg';
import routes from '../../../router/routes';
import { translate } from '../../../state/locale/selectors/translate';
import { ImageSection } from '../../_layout/ImageSection';
import PageWrapper from '../../_layout/PageWrapper';
import Breadcrumbs from '../../reusable/Breadcrumbs';
import { Loading } from '../../reusable/Loading';
import { Fund } from '../../templates/Fund';

const FundScreen = () => {
  const i18n = useRecoilValue(translate).fund;
  const breadcrumbs = [
    {
      link: routes.fund,
      title: i18n.breadcrumb,
    },
  ];

  return (
    <React.Suspense fallback={<Loading />}>
      <ImageSection id={'fund'} image={background} />
      <PageWrapper>
        <Breadcrumbs base={breadcrumbs} />
        <Fund />
      </PageWrapper>
    </React.Suspense>
  );
};

export default FundScreen;
