import * as React from 'react';
import { useEffect } from 'react';

import { useLocation } from 'react-router-dom';
import { useRecoilValue } from 'recoil';
import useSWR from 'swr';

import { Ids } from '../../../constants/Ids';
import scrollToHash from '../../../misc/helpers/scrollToHash';
import { ITenantResponse } from '../../../models/Tenant';
import Fetcher from '../../../services/Fetcher';
import { locale } from '../../../state/locale/atoms/locale';
import { translate } from '../../../state/locale/selectors/translate';
import { Address } from '../../reusable/Address';
import { Loading } from '../../reusable/Loading';
import styles from './Tenant.module.scss';

export const Tenant = () => {
  const lang = useRecoilValue(locale);
  const i18n = useRecoilValue(translate).rent;
  const location = useLocation();
  const { data, isValidating } = useSWR<ITenantResponse>(
    ['/tenant', lang],
    Fetcher.get
  );

  useEffect(() => {
    scrollToHash(Boolean(data), location.hash, 500);
  }, [lang, data, location.hash]);

  if (!data || isValidating) return <Loading />;

  return (
    <div className={styles.tenantWrapper}>
      <h2 className={styles.pageTitle} id={Ids.tenantApplication}>
        {data.applicationTitle}
      </h2>
      <p className={styles.paragraph}>{data.applicationDescription}</p>
      {Boolean(data.sections.length) &&
        data.sections.map((section) => {
          switch (section.__component) {
            case 'tenant.title':
              return (
                <h3 className={styles.pageSubtitle}>{section.headerText}</h3>
              );
            case 'tenant.list':
              return (
                <ul className={styles.list}>
                  {section.listItem.map((listItem) => {
                    switch (listItem.type) {
                      case 'bullet':
                        return (
                          <li className={styles.listIItem}>{listItem.label}</li>
                        );
                      case 'nested':
                        return (
                          <li className={styles.nested}>{listItem.label}</li>
                        );
                      case 'link':
                        return (
                          <li>
                            <a
                              href={listItem.link}
                              download={listItem.download}
                            >
                              {listItem.label}
                            </a>
                          </li>
                        );
                      default:
                        return null;
                    }
                  })}
                </ul>
              );
            default:
              return null;
          }
        })}
      <div className={styles.tenantServiceWrapper} id={Ids.tenantService}>
        <div className={styles.copyWrapper}>
          <h2 className={styles.pageTitle}>{i18n.tenantServiceTitle}</h2>
          <p className={styles.paragraph}>{data.tenantService.description}</p>
        </div>
        <Address address={data.tenantService.address} />
        <p
          className={styles.paragraph}
          children={data.tenantService.officeHours}
        />
      </div>
    </div>
  );
};
