import * as React from 'react';

import cn from 'classnames';
import { Link } from 'react-router-dom';
import { useRecoilValue } from 'recoil';

import getIconClass from '../../../misc/helpers/getIconClass';
import routes from '../../../router/routes';
import { currentBreadcrumb } from '../../../state/breadcrumbs/atoms/currentBreadcrumb';
import styles from './Breadcrumbs.module.scss';
import { IBreadcrumbsProps } from './Breadcrumbs.types';

export const Breadcrumbs = ({ base }: IBreadcrumbsProps) => {
  const current = useRecoilValue<string>(currentBreadcrumb);

  const indexWhenShowArrow = base.length - 1;

  return (
    <ul className={styles.breadcrumbs}>
      <li key="home">
        <Link to={routes.index}>
          <span className={cn(styles.homeIcon, getIconClass.home)} />
        </Link>
        {(current || base.length) && <span className={styles.arrow} />}
      </li>

      {base.map((breadcrumb, index) => (
        <li key={breadcrumb.link}>
          <Link to={breadcrumb.link}>{breadcrumb.title}</Link>
          {(current || index < indexWhenShowArrow) && (
            <span className={styles.arrow} />
          )}
        </li>
      ))}

      {current && <li key="current">{current}</li>}
    </ul>
  );
};

export default Breadcrumbs;
