export const de = {
  about: {
    breadcrumb: 'Über uns',
  },
  articles: {
    estate: {
      breadcrumb: 'Immobilien',
    },
    sponsorship: {
      breadcrumb: 'Sponsoring',
    },
  },
  department: {
    button: 'Gehe zur Seite',
  },
  estate: {
    breadcrumb: 'Immobilien',
    proposition:
      'Haben Sie ein interessantes Angebot für uns oder möchten uns ein Exposé zusenden, so kontaktieren Sie uns bitte.',
    linkButton: 'Gehe zur Seite',
    linkDescription:
      'Wenn Sie unsere Immobilie kaufen oder mieten möchten, finden Sie hier alle aktuellen Angebote:',
  },
  fund: {
    breadcrumb: 'Investmentfonds',
    pageTitle: 'Investmentfonds',
  },
  investments: {
    breadcrumb: 'Investitionen',
    page: {
      button: 'Gehe zur Seite',
    },
  },
  jobs: {
    breadcrumb: 'Jobs & Karriere',
    pageTitle: 'Jobs & Karriere',
    offers: 'Aktuell suchen wir Verstärkung in den folgenden Bereichen',
  },
  purchase: {
    breadcrumb: 'Ankauf',
    pageTitle: 'Ankauf',
  },
  rent: {
    breadcrumb: 'Mieten',
    pageTitle: 'Mieten',
    linkButton: 'Gehe zur Seite',
    linkDescription:
      'Wenn Sie unsere Immobilie kaufen oder mieten möchten, finden Sie hier alle aktuellen Angebote:',
    tenantApplication: 'Mieterantrag',
    tenantServiceTitle: 'Mieterservice',
  },
  sale: {
    breadcrumb: 'Verkauf',
    pageTitle: 'Verkauf',
  },
  sponsorship: {
    breadcrumb: 'Sponsoring',
    title: 'Sponsoring',
    description:
      'Powder jujubes bonbon sesame snaps cake jujubes. Jelly beans bonbon biscuit croissant muffin soufflé muffin candy. Halvah sweet cupcake bear claw marshmallow muffin. Liquorice tart cotton candy topping lollipop sweet.',
  },
  pages: {
    breadcrumb: 'Seite',
  },
  press: {
    breadcrumb: 'Presse',
    description:
      'Powder jujubes bonbon sesame snaps cake jujubes. Jelly beans bonbon biscuit croissant muffin soufflé muffin candy. Halvah sweet cupcake bear claw marshmallow muffin. Liquorice tart cotton candy topping lollipop sweet.',
    title: 'Presseveröffentlichungen',
  },
  contact: {
    breadcrumb: 'Kontakt',
    form: {
      email: 'E-mail',
      message: 'Botschaft',
      name: 'Vorname und Nachname',
      send: 'Senden',
    },
    success: {
      breadcrumb: 'Kontakt',
      button: 'Zurück',
      message: 'Vielen Dank, wir haben Ihre Nachricht erhalten!',
    },
    policy: {
      agreement: 'Ich habe gelesen und akzeptiere',
      impressumLink: '/pdf/impressum_de.pdf',
      link: '/pdf/privacy_policy_de.pdf',
      linkTitle: 'Datenschutz-Bestimmungen',
    },
  },
  errors: {
    error404: {
      breadcrumb: '404 Seite nicht gefunden',
      question: 'Ist das die richtige Adresse?',
      text: 'Die von Ihnen angeforderte Seite existiert nicht.',
    },
  },
  tenant: {
    breadcrumb: 'Mieterbereich',
    pageTitle: 'Mieterbereich',
  },
};
