import * as React from 'react';

import { useRecoilValue } from 'recoil';
import useSWR from 'swr';

import { IPurchaseResponse } from '../../../models/Purchase';
import Fetcher from '../../../services/Fetcher';
import { locale } from '../../../state/locale/atoms/locale';
import { Sections } from '../../organisms/Sections';
import { Loading } from '../../reusable/Loading';

export const Purchase = () => {
  const lang = useRecoilValue(locale);
  const { data, isValidating } = useSWR<IPurchaseResponse>(
    ['/purchase', lang],
    Fetcher.get
  );

  if (!data || isValidating) return <Loading />;

  return <Sections sections={data.sections} />;
};
