import * as React from 'react';
import { useEffect, useState } from 'react';

import cn from 'classnames';
import { useRecoilValue } from 'recoil';

import ContactApi from '../../../services/$contact-api';
import { IContactResponse } from '../../../services/$contact-api/types';
import { locale } from '../../../state/locale/atoms/locale';
import { Address } from '../../reusable/Address';
import { GoogleMaps } from '../../reusable/GoogleMaps';
import { Loading } from '../../reusable/Loading';
import { ContactForm } from './components/ContactForm';
import styles from './Contact.module.scss';

export const Contact = () => {
  const lang = useRecoilValue(locale);
  const [page, setPage] = useState<IContactResponse | null>(null);

  useEffect(() => {
    ContactApi.getContactPage(lang).then((response) => setPage(response));
  }, [lang]);

  return page ? (
    <div className={styles.contactWrapper}>
      <div className={styles.column}>
        <h2 className={styles.companyName}>{page.name}</h2>
        {page.address.map((address) => (
          <Address address={address} key={address.id} />
        ))}
        <ContactForm />
      </div>

      <div className={cn(styles.column, styles.right)}>
        <GoogleMaps locale={lang} map={page.map} />
      </div>
    </div>
  ) : (
    <Loading />
  );
};
