/**
 * Function that scrolls to an element when ready.
 *
 * @param {boolean} ready - indicator if it's ready to scroll
 * @param {string} hash - element id (#id)
 * @param {number} timeout - delay to execute function
 */
const scrollToHash = (
  ready: boolean,
  hash?: string,
  timeout?: number
): void => {
  if (ready && hash) {
    setTimeout(() => {
      const element = document.getElementById(hash.replace('#', ''));

      if (element) {
        window.scrollTo(0, element.offsetTop - 150);
      }
    }, timeout);
  }
};

export default scrollToHash;
