import * as React from 'react';

import { Link } from '../../reusable/Link';
import { ParagraphText } from '../../reusable/ParagraphText';
import { ISectionsProps } from './Sections';
import styles from './Sections.module.scss';

export const Sections = ({ sections }: ISectionsProps) => (
  <div className={styles.sectionsWrapper}>
    {sections.map((section) => {
      switch (section.__component) {
        case 'sections.title':
          return <h2 className={styles.pageTitle}>{section.headerText}</h2>;
        case 'sections.paragraph':
          return <ParagraphText text={section.paragraphText} />;
        case 'sections.button':
          return (
            <Link className={styles.buttonLink} to={section.link} blank={true}>
              {section.label}
            </Link>
          );
        default:
          return null;
      }
    })}
  </div>
);
