import { ITranslation } from '../models/Locale';

export const en: ITranslation = {
  about: {
    breadcrumb: 'About us',
  },
  articles: {
    estate: {
      breadcrumb: 'Estate',
    },
    sponsorship: {
      breadcrumb: 'Sponsorship',
    },
  },
  department: {
    button: 'Go to page',
  },
  estate: {
    breadcrumb: 'Estates',
    proposition:
      'If you have an interesting offer, or would like to send us an exposé, please contact us.',
    linkButton: 'Go to page',
    linkDescription:
      'If you are interested in buying or renting our property, you can find all the current offers here:',
  },
  fund: {
    breadcrumb: 'Investment fund',
    pageTitle: 'Investment fund',
  },
  investments: {
    breadcrumb: 'Investments',
    page: {
      button: 'Go to page',
    },
  },
  jobs: {
    breadcrumb: 'Jobs & Careers',
    pageTitle: 'Jobs & Careers',
    offers:
      'We are currently looking for reinforcement in the following areaWe are currently looking for reinforcement in the following areas',
  },
  purchase: {
    breadcrumb: 'Purchase',
    pageTitle: 'Purchase',
  },
  rent: {
    breadcrumb: 'Rent',
    pageTitle: 'Rent',
    linkButton: 'Go to page',
    linkDescription:
      'If you are interested in buying or renting our property, you can find all the current offers here:',
    tenantApplication: 'Tenant application',
    tenantServiceTitle: 'Tenant service',
  },
  sale: {
    breadcrumb: 'Sale',
    pageTitle: 'Sale',
  },
  sponsorship: {
    breadcrumb: 'Sponsorship',
    title: 'Sponsorship',
    description:
      'Soufflé sugar plum bear claw dragée brownie candy canes caramels wafer candy. Marzipan chocolate fruitcake bear claw chupa chups cupcake gummi bears. Cupcake halvah muffin dessert biscuit. Tootsie roll cookie ice cream chocolate cake pastry.',
  },
  pages: {
    breadcrumb: 'Page',
  },
  press: {
    breadcrumb: 'Press',
    description:
      'Soufflé sugar plum bear claw dragée brownie candy canes caramels wafer candy. Marzipan chocolate fruitcake bear claw chupa chups cupcake gummi bears. Cupcake halvah muffin dessert biscuit. Tootsie roll cookie ice cream chocolate cake pastry.',
    title: 'Press publications',
  },
  contact: {
    breadcrumb: 'Contact',
    form: {
      email: 'E-mail',
      message: 'Message',
      name: 'Name',
      send: 'Send',
    },
    success: {
      breadcrumb: 'Contact',
      button: 'Back',
      message: "Thank you, we've received your message!",
    },
    policy: {
      agreement: 'I have read and accept',
      impressumLink: '/pdf/impressum_de.pdf',
      link: '/pdf/privacy_policy_en.pdf',
      linkTitle: 'Privacy policy',
    },
  },
  errors: {
    error404: {
      breadcrumb: '404 Page not found',
      question: 'Are you sure this is the correct address?',
      text: 'Page you requested does not exist.',
    },
  },
  tenant: {
    breadcrumb: 'Tenant section',
    pageTitle: 'Tenant section',
  },
};
