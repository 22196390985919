import * as React from 'react';

import { Link } from '../../../../reusable/Link';
import styles from './MainMenu.module.scss';
import { IMainMenuProps } from './MainMenu.types';

export const MainMenu = ({ menu }: IMainMenuProps) => (
  <ul className={styles.mainMenuWrapper}>
    {menu.map((navLink) => (
      <li className={styles.menuItem} key={navLink.id}>
        <Link
          to={navLink.link}
          className={styles.navLink}
          navLink={true}
          blank={navLink.newWindow}
        >
          {navLink.title}
        </Link>

        {navLink.dropdownItems && (
          <div className={styles.dropdownContainer}>
            {navLink.dropdownItems?.map((link) => (
              <Link
                to={link.link}
                blank={navLink.newWindow}
                className={styles.dropdownItem}
                key={link.id}
              >
                {link.title}
              </Link>
            ))}
          </div>
        )}
      </li>
    ))}
  </ul>
);
